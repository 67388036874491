@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Dancing+Script&family=El+Messiri&family=Satisfy&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins 300', sans-serif;
}

.bg {
  background: rgb(255, 249, 238);
}

/****************Navbar*****************/
.navbar {
  background-color: #016b8b;
  /* border-bottom: 2px solid orange; */
  border-bottom: 3px solid #ffffff;
}

.nav-link {
  color: #ffffff !important;
  /* font-size: medium !important; */
  font-weight: 500 !important;
  cursor: pointer;
}

@media (max-width: 575px) {
  .nav-link {
    color: #000 !important;
  }
}

.navbar-toggler {
  background-color: #84ac41 !important;
  margin: 3px !important;
  font-size: smaller !important;
}

.fixed {
  transition: .3s ease-in-out;
  position: fixed !important;
  top: 0;
  z-index: 999;
  width: 100%;
}

@media (max-width: 575px) {
  .fixed {
    bottom: -5px;
    top: auto;
  }
}


body {
  background-color: #fffefc;
}

/*******Footer*********/
.footer {
  background-color: #084298;
  padding-top: 20px;
}

a {
  text-decoration: none !important;
}

/****************Banner *******************/
.card-title,
.card-text {
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  background-color: black;
  opacity: 0.5;
}

.card-box {
  /* margin-bottom: 20px; */
  border: 1px solid orange !important;
  cursor: pointer;
  min-height: 160px;
}

.card-box:hover {
  box-shadow: 0 10px 10px #777;
  /* margin-top: -10px; */
  border: none !important;
  border-top: 2px solid orange !important;
}

.image {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 13px 0px black;
}

.image img {
  /* border: 1px solid #084298;
  height: 150px !important;
  border-radius: 10px; */

}

p {
  font-size: small;
}

.doctor-profile {
  /* height: 100px; */
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}

.service-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-style: dashed;
  overflow: hidden;
}

.about {
  min-height: 80px;
}

.line {
  width: 200px;
  height: 2px;
  background-color: black;
  margin: 0 auto;
  /* Centers the line horizontally */

}


@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blinking-button {
  animation: blink 2s infinite;
}


.text-small {
  font-size: small;
}



.rdrMonth {
  width: 100% !important;
}

.time-pills {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.time-pills div.pill-size {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 12px;
}

.highlight * {
  color: black;
  background-color: pink !important;
  border-radius: 50%;
  padding: 12px;
}

.react-calendar {
  width: 100%;
  margin: 16px 0;
}

.swal2-container {
  z-index: 9999 !important;
}

.time-show {
  display: none;
  left: 50px;
  top: 12px;
  background: red;
  color: white;
  border-radius: 19px;
}

.time-hover:hover .time-show {
  display: block;
}
.dashba .MuiTypography-root {
  color:#016b8b;
} 
.dashba .MuiListItemIcon-root.css-cveggr-MuiListItemIcon-root svg{
  color:#016b8b;
}